import config from './config';

const aclModuleURL = config.getParameter('ACL_MODULE_URL') || '';
const cookieName = config.getParameter('ACL_MODULE_COOKIE_NAME') || 'sh.acl.auth';

function isACLModuleEnabled() {
  try {
    return JSON.parse(config.getParameter('ACL_MODULE_ENABLED'));
  } catch (e) {
    return false;
  }
}

function redirectToSignIn() {
  const encodedCurrentLocation = encodeURIComponent(window.location.href);

  window.location.assign(`${aclModuleURL}/v1/sign-in?redirect_url=${encodedCurrentLocation}`);
}

function requestUserAccess() {
  if (!isACLModuleEnabled()) {
    // eslint-disable-next-line no-console
    console.log('ACL Module disabled, ignoring');
    return Promise.resolve();
  }

  if (!document.cookie.includes(cookieName)) {
    return Promise.reject(Error('Auth cookie missing'));
  }

  return Promise.resolve();
}

export default {
  requestUserAccess,
  redirectToSignIn,
};
