import axios from '../../plugins/axios';

/**
 * Fetch from the api all applications.
 * @param context
 * @returns {Promise<void>}
 */
const getApplications = (context) => {
  context.commit('isLoading', true);
  const state = context.rootState;
  const params = {
    country: state.countryCode,
  };

  context.rootState.selectedApplication = null;
  context.rootState.entities = [];
  context.rootState.selectedEntity = null;
  context.rootState.actions = [];
  context.rootState.selectedAction = null;
  state.page = 1;

  return axios.get('/v2/applications', { params })
    .then((response) => {
      context.commit('setApplications', response.data.applications ?? []);
    })
    .catch((err) => {
      context.dispatch({
        type: 'openWarningModal',
        payload: `Api request failed with error: ${err.response.data}`,
        code: err.response.status,
      });
    })
    .finally(() => context.commit('isLoading', false));
};

/**
 * Fetch from the api all entities for a specific application.
 * @param context
 * @param state
 * @returns {Promise<void>}
 */
const getEntities = (context, state) => {
  context.commit('isLoading', true);
  const params = {
    country: state.countryCode,
  };

  state.selectedEntity = null;
  state.actions = [];
  state.selectedAction = null;
  state.page = 1;

  return axios.get(`/v2/applications/${state.selectedApplication}/entities`, { params })
    .then((response) => {
      context.commit('setEntities', response.data.entities ?? []);
    })
    .catch((err) => {
      context.dispatch({
        type: 'openWarningModal',
        payload: `Api request failed with error: ${err.response.data}`,
        code: err.response.status,
      });
    })
    .finally(() => context.commit('isLoading', false));
};

/**
 * Fetch from the api all action for a specific action.
 * @param context
 * @param state
 * @returns {Promise<void>}
 */
const getActions = (context, state) => {
  context.commit('isLoading', true);
  const params = {
    country: state.countryCode,
  };

  state.selectedAction = null;
  state.page = 1;

  return axios.get(`/v2/applications/${state.selectedApplication}/entities/${state.selectedEntity}/actions`, { params })
    .then((response) => {
      context.commit('setActions', response.data.actions ?? []);
    })
    .catch((err) => {
      context.dispatch({
        type: 'openWarningModal',
        payload: `Api request failed with error: ${err.response.data}`,
        code: err.response.status,
      });
    })
    .finally(() => context.commit('isLoading', false));
};

export default {
  getApplications,
  getEntities,
  getActions,
};
