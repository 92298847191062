import helpers from '../helpers';

const defaults = require('./defaults');

const getParameter = (key) => {
  if (helpers.keyExistsInObject(window.CONFIG, key)) {
    return window.CONFIG[key];
  }

  return defaults[key];
};

export default {
  getParameter,
};
