import Axios from 'axios';
import config from '../../config';
import logger from '../logger';

const client = Axios;

client.defaults.params = {};

client.defaults.baseURL = config.getParameter('api_url');

client.interceptors.request.use(
  (axiosConfig) => {
    const token = config.getParameter('token');
    // const token = localStorage.getItem('token');
    if (token) {
      axiosConfig.headers.Authorization = `${token}`;
    }
    axiosConfig.withCredentials = true;
    return axiosConfig;
  },
  (error) => Promise.reject(error),
);

client.interceptors.response.use((response) => response, (error) => {
  logger.send(error.response.data);
  return Promise.reject(error);
});

export default client;
