import Vue from 'vue';
import Router from 'vue-router';
// import axios from '../plugins/axios';
import routes from './routes';

Vue.use(Router);

const router = new Router({
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  routes,
});

// const validateToken = (next, to) => axios.post('/v2/login/verify', {
//   token: localStorage.getItem('token'),
// })
//   .then(() => next())
//   .catch((error) => {
//     if (error.response.status !== 200) {
//       localStorage.removeItem('token');

//       return next({
//         path: '/login',
//         params: { nextUrl: to.fullPath },
//       });
//     }
//     return next();
//   });

router.beforeEach((to, from, next) => {
  localStorage.setItem('token', 'superToken');
  return next();
  // if (to.matched.some((record) => record.meta.requiresAuth)) {
  //   if (localStorage.getItem('token') == null) {
  //     return next({
  //       path: '/login',
  //     });
  //   }
  //   return validateToken(next, to);
  // }
  // return next();
});

export default router;
