<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="logs"
      :options="options"
      :footer-props="footerProps"
      :server-items-length="numberOfItems"
      class="elevation-1"
      :page.sync="currentPage"
      @update:items-per-page="setLimit"
      @update:page="setPage"
    >
      <v-progress-linear
        slot="progress"
        indeterminate
      />
      <template v-slot:item.action_date="{ item }">
        {{ formatDate(item.action_date) }}
      </template>

      <template v-slot:item.payload="{ item }">
        <v-btn
          color="primary"
          @click="setPayload(item.payload)"
          @click.stop="openModal"
        >
          Show Payload
        </v-btn>
      </template>
      <template v-slot:item.diff="{ item }">
        <v-btn
          color="primary"
          @click="showDiff(item)"
        >
          Show Diff
        </v-btn>
      </template>
    </v-data-table>
    <json-formatter
      :payload="payload"
    />
    <diff-json />
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import JsonFormatter from './JsonFormatter.vue';
import DiffJson from './JsonDiff.vue';

export default {
  components: {
    JsonFormatter,
    DiffJson,
  },
  data() {
    return {
      payload: '',
      payloadDialog: false,
      footerProps: {
        'items-per-page-options': [10, 25, 50],
      },
      options: {
        itemsPerPage: 10,
      },
      headers: [
        { text: 'Application', value: 'application_name', sortable: false },
        { text: 'Entity Type', value: 'entity_type', sortable: false },
        { text: 'Entity ID', value: 'entity_id', sortable: false },
        { text: 'Entity Name', value: 'entity_name', sortable: false },
        { text: 'Action Type', value: 'action', sortable: false },
        { text: 'User Email', value: 'email_user', sortable: false },
        { text: 'User ID', value: 'user_id', sortable: false },
        { text: 'Date', value: 'action_date', sortable: false },
        {
          text: 'Payload', value: 'payload', sortable: false, align: 'center',
        },
        {
          text: 'Diff', value: 'diff', sortable: false, align: 'center',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      logs: 'getLogs',
      numberOfItems: 'getNumberOfLogs',
      page: 'getPage',
    }),
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        return val;
      },
    },
  },
  methods: {
    showDiff(val) {
      this.$store.dispatch('getDiff', val);
    },
    setPayload(payload) {
      this.payload = payload;
    },
    openModal() {
      this.$store.commit('changePayloadModalOpenStatus', true);
    },
    setLimit(val) {
      this.$store.commit('setPerPage', val);
    },
    setPage(val) {
      this.$store.commit('setPage', val);
    },
    formatDate(val) {
      return moment(val).format('DD/MM/YYYY, HH:mm:ss');
    },
  },
};
</script>
<style>
#app .v-data-footer__pagination {
  display: none;
}
</style>
