import * as R from 'ramda';
import moment from 'moment';
import helpers from '../../helpers';

// Format Calendar Dates
const formatStartDate = (date) => ((!date) ? null : `${moment(date).format('YYYY-MM-DD')} 00:00:00`);
const formatEndDate = (date) => ((!date) ? null : `${moment(date).format('YYYY-MM-DD')} 23:59:59`);

const parseAndSaveNewFilters = (state) => {
  const filters = helpers.removeInvalidFilters({
    application_id: state.selectedApplication,
    entity_type_id: state.selectedEntity,
    action_id: state.selectedAction,
    date_from: formatStartDate(state.startDate),
    date_to: formatEndDate(state.endDate),
  });

  if (!helpers.isNullOrEmpty(state.textSearchType) && !helpers.isNullOrEmpty(state.textSearchValue)) {
    filters[state.textSearchType] = state.textSearchValue;
  }

  return filters;
};

const mapFiltersToApiRequest = (state, page, limit, country) => {
  const data = parseAndSaveNewFilters(state);

  if (page === 1) {
    data.page_from = 0;
  } else {
    data.page_from = (page - 1) * limit;
  }

  data.page_to = limit;
  data.country = country;

  return data;
};

const mapGetDiffRequest = (item, country) => {
  const data = {
    country,
  };

  const fields = [
    'id',
    'application_id',
    'entity_type_id',
    'entity_id',
    'revision',
    'action_id',
  ];

  return R.mergeRight(data, R.pick(fields, item));
};

export default {
  mapFiltersToApiRequest,
  mapGetDiffRequest,
};
