import axios from 'axios';

const client = axios.create({});

const send = (message) => client.post('/_logger', { message })
  .catch(() => Promise.resolve());

export default {
  send,
};
