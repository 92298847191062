<template>
  <div class="text-xs-center">
    <VDialog
      ref="modal"
      :value="status"
      width="500"
    >
      <VCard>
        <VCardTitle
          class="headline grey lighten-2"
          primary-title
        >
          Warning
        </VCardTitle>

        <VCardText>
          <div class="warning-text">
            {{ text }}
          </div>
        </VCardText>

        <VDivider />

        <VCardActions>
          <VSpacer />
          <VBtn
            color="primary"
            @click="closeModal(false)"
          >
            Close
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'WarningComponent',
  computed: {
    ...mapGetters({
      status: 'getWarningStatus',
      text: 'getWarningText',
    }),
  },
  mounted() {
    this.$watch(
      () => this.$refs.modal.isActive,
      (val) => {
        this.$store.commit('setWarningStatus', val);
      },
    );
  },
  methods: {
    closeModal() {
      this.$store.commit('setWarningStatus', false);
    },
  },
};
</script>

<style scoped>
    .warning-text {
        color: black;
        padding-top: 5% !important;
    }
</style>
