import filtersApiService from '../api/filters';
import helpers from '../../helpers';

const mutations = [
  'setApplication',
  'setEntity',
  'setAction',
  'setStartDate',
  'setEndDate',
];

export default (store) => {
  store.subscribe((mutation) => {
    // If the application type is changed and the value is null, reset all information
    if (mutation.type === 'setApplication' && helpers.isNullOrEmpty(store.state.selectedApplication)) {
      store.dispatch('removeApplicationInformation');
    } else if (mutations.includes(mutation.type)) {
      // eslint-disable-next-line default-case
      switch (mutation.type) {
        case 'setApplication':
          filtersApiService.getEntities(store, store.state);
          break;
        case 'setEntity':
          filtersApiService.getActions(store, store.state);
          break;
      }

      store.dispatch('fetchLogsFromApi');
    }
  });
};
