import Vue from 'vue';
import logger from '../logger';

// eslint-disable-next-line no-unused-vars
Vue.config.errorHandler = (err, vm, info) => logger.send({ err, vm, info });

// eslint-disable-next-line no-unused-vars,func-names
window.onerror = function (message, source, lineno, colno, error) {
  return logger.send({
    message, source, lineno, colno, error,
  });
};
