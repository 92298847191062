<template>
  <div class="logs-page">
    <Header class="header" />
    <VProgressLinear
      :active="loading"
      :indeterminate="true"
    />
    <Filters class="filters" />
    <VContainer
      grid-list-xl
      fluid
      class="table"
    >
      <LogsTable />
    </VContainer>
    <Footer class="footer" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import LogsTable from '../components/LogsTable.vue';
import Filters from '../components/Filters.vue';

export default {
  components: {
    Header,
    Footer,
    LogsTable,
    Filters,
  },
  computed: {
    ...mapGetters({
      loading: 'isRequestLoading',
    }),
  },
  beforeMount() {
    this.$store.dispatch('fetchInitialData');
  },
};
</script>
