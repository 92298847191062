<template>
  <v-dialog
    ref="diffDialog"
    :value="opened"
    max-width="700px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Diff</span>
      </v-card-title>
      <v-card-text>
        <tr>
          <td>
            <pre
              id="showDiff"
              v-html="preparedPayload"
            />
          </td>
        </tr>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          @click.stop="closeModal"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      preparedPayload: null,
    };
  },
  computed: {
    ...mapGetters({
      opened: 'getDiffModalStatus',
      payload: 'getDiffModalContent',
    }),
  },
  watch: {
    payload(val) {
      this.preparedPayload = val;
    },
  },
  mounted() {
    this.$watch(
      () => this.$refs.diffDialog.isActive,
      (val) => {
        this.$store.commit('setDiffModalOpenStatus', val);
      },
    );
  },
  methods: {
    closeModal() {
      this.$store.commit('setDiffModalOpenStatus', false);
    },
  },
};
</script>

<style lang="scss">
  #showDiff {
    .diff {
      display: inline-block;
    }

    .diff-level {
      margin-left: 1.6em;
    }

    .diff-holder {
      color: #666;
      margin: 0;
    }

    .diff-holder span {
      color: #AAA;
    }

    del.diff,
    ins.diff {
      text-decoration: none;
    }

    del.diff {
      color: #b30000;
      background: #fadad7;
    }

    ins.diff {
      background: #eaf2c2;
      color: #406619;
    }

    del.diff-key {
      border: 1px solid #f8a4a4;
    }

    ins.diff-key {
      border: 1px solid #a3ce4c;
      margin-top: -1px;
      position: relative;
    }

    ins.diff span {
      color: #AABF40;
    }

    del.diff span {
      color: #EE8177;
    }

    height: 100%;
    width: 100%;
    margin: auto;
    text-align: left;
    white-space: pre-wrap;
    word-break: break-all !important;
  }
</style>
