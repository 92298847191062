import helpers from '../../helpers';

const objectDiff = require('objectdiff');
const R = require('ramda');

const prepareObjectToDiff = (obj) => {
  try {
    obj.payload = JSON.parse(obj.payload);
  } catch (e) {
    obj.payload = {};
  }

  return obj;
};

const getPayload = R.propOr({}, 'payload');

const diffObjects = (obj1, obj2) => {
  const diff = objectDiff.diffOwnProperties(getPayload(obj1), getPayload(obj2));
  return objectDiff.convertToXMLString(diff);
};

const diff = (data, newObject) => {
  if (helpers.isNullOrEmpty(data)) {
    return 'No diff available';
  }

  newObject = prepareObjectToDiff({ ...newObject });
  const oldObject = prepareObjectToDiff(data);

  return diffObjects(oldObject, newObject);
};

export default diff;
