import * as R from 'ramda';

const isNullOrEmpty = R.either(R.isNil, R.isEmpty);

const inArrayWithCaseInsensitive = R.curry((array, key) => {
  if (isNullOrEmpty(array) || isNullOrEmpty(key)) {
    return false;
  }

  for (let i = 0; i < array.length; i++) {
    if (key.toLowerCase() === array[i].toLowerCase()) {
      return true;
    }
  }
  return false;
});

const inArray = (array, key) => {
  if (isNullOrEmpty(array) || isNullOrEmpty(key)) {
    return false;
  }
  for (let i = 0; i < array.length; i++) {
    // eslint-disable-next-line eqeqeq
    if (key == array[i]) {
      return true;
    }
  }
  return false;
};

const keyExistsInObject = (object, key) => {
  if (isNullOrEmpty(object) || isNullOrEmpty(key)) {
    return false;
  }

  if (Object.prototype.hasOwnProperty.call(object, key)) {
    return !isNullOrEmpty(object[key]);
  }

  return false;
};

const getDataFromAggregation = (data, key) => {
  if (!R.is(Array, data)) {
    return [];
  }

  for (let i = 0; i < data.length; i++) {
    if (data[i].Key === key) {
      return data[i].Results;
    }
  }

  return [];
};

const isJsonString = R.curry((str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
});

const removeInvalidFilters = R.filter(R.complement(R.isNil));

const parseString = R.ifElse(
  isJsonString,
  (a) => JSON.parse(a),
  R.identity,
);

const lastArrayOrFalse = R.ifElse(
  R.allPass([R.is(Array), R.compose(R.lt(1), R.length)]),
  R.last,
  R.F,
);

const getCountryFromHost = R.ifElse(
  R.is(String),
  R.compose(
    lastArrayOrFalse,
    R.split('.'),
  ),
  R.F,
);

export default {
  isNullOrEmpty,
  inArrayWithCaseInsensitive,
  inArray,
  keyExistsInObject,
  getDataFromAggregation,
  parseString,
  removeInvalidFilters,
  getCountryFromHost,
};
