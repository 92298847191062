import LogsPage from '../pages/Logs.vue';
import LoginPage from '../pages/Login.vue';
import NotFoundPage from '../pages/NotFound.vue';

export default [
  {
    path: '/',
    name: 'LogsPage',
    component: LogsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '*',
    name: 'NotFoundPage',
    component: NotFoundPage,
    meta: {
      requiresAuth: false,
    },
  },
];
