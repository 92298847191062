<template>
  <v-dialog
    ref="dialog"
    :value="opened"
    max-width="700px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Payload</span>
      </v-card-title>
      <v-card-text>
        <json-view
          :data="prettyPayload"
          :max-depth="2"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          @click.stop="closeModal"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { JSONView } from 'vue-json-component';

export default {
  components: {
    'json-view': JSONView,
  },
  props: {
    payload: {
      default: null,
      type: String,
    },
  },
  computed: {
    prettyPayload() {
      return this.payload ? JSON.parse(this.payload) : {};
    },
    ...mapGetters({
      opened: 'getPayloadModalOpenStatus',
    }),
  },
  mounted() {
    this.$watch(
      () => this.$refs.dialog.isActive,
      (val) => {
        this.$store.commit('changePayloadModalOpenStatus', val);
      },
    );
  },
  methods: {
    closeModal() {
      this.$store.commit('changePayloadModalOpenStatus', false);
    },
  },
};
</script>

<style>
  .value-key {
    white-space: unset !important;
    word-break: break-all !important;
  }
</style>
