import moment from 'moment';
import helpers from '../../helpers';

export default (store) => {
  store.subscribe((mutation) => {
    if (mutation.type === 'setStartDate') {
      if (!helpers.isNullOrEmpty(store.state.endDate)
        && moment(store.state.startDate).isAfter(moment(store.state.endDate), 'day')
      ) {
        store.state.endDate = null;
      }
    }
  });
};
