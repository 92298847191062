<template>
  <VApp
    id="login"
    class="primary"
  >
    <VContent>
      <VContainer
        fluid
        fill-height
      >
        <VLayout
          align-center
          justify-center
        >
          <VFlex
            xs12
            sm8
            md6
            lg4
          >
            <VCard class="elevation-1 pa-3">
              <VCardText>
                <div class="layout column align-center">
                  <h1 class="flex my-4 primary--text">
                    Logging System
                  </h1>
                </div>
              </VCardText>
              <VCardActions>
                <v-btn
                  color="primary"
                  :disabled="loading"
                  class="v-btn v-btn--block theme--light primary login-button"
                  @click="redirectToAclModule"
                >
                  <img
                    class="login-img"
                    src="../assets/sword-icon.svg"
                  >
                  <span class="ng-binding">Sign in with Sword Health</span>
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="primary"
                    class="loading-bar"
                  />
                </v-btn>
              </VCardActions>
            </VCard>
          </VFlex>
        </VLayout>
      </VContainer>
    </VContent>
    <WarningComponent />
  </VApp>
</template>

<script>
import { mapGetters } from 'vuex';
import WarningComponent from '../components/Warning.vue';
import helpers from '../helpers';

export default {
  components: {
    WarningComponent,
  },
  data: () => ({
    redirectUrl: '',
    loading: false,
  }),
  computed: {
    ...mapGetters({
      country: 'getCountryCode',
    }),
  },
  beforeMount() {
    if (this.verifyIfTokenExists()) {
      this.$router.push({ name: 'LogsPage' });
    }

    this.redirectUrl = this.createRedirectLink();

    if (!helpers.isNullOrEmpty(this.$route.query.code)) {
      this.loading = true;
      const { code } = this.$route.query;

      this.$http.post('/v2/login', { token: code, country: this.country })
        .then((response) => {
          localStorage.setItem('token', response.data.token);
          this.$router.push({ name: 'LogsPage' });
        })
        .catch((err) => {
          this.$store.commit('setWarningText', err.response.data);
          this.$store.commit('setWarningStatus', true);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  methods: {
    redirectToAclModule() {
      window.location.href = this.redirectUrl;
    },
    createRedirectLink() {
      const aclEndpoint = 'test.redirect';
      const { protocol, host } = window.location;

      return `${aclEndpoint}/login?redirect=${protocol}//${host}/login&logout=true&application=loggingsystem&aclFlow=v2`;
    },
    verifyIfTokenExists() {
      const token = localStorage.getItem('token');
      return !helpers.isNullOrEmpty(token);
    },
  },
};
</script>
<style scoped lang="css">
  #login {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
  }

  .login-button {
    cursor: pointer;
  }

  .login-img {
    margin-right: 10px !important;
    width: 20px !important;
  }

  .loading-bar {
    margin-left: 3% !important;
  }
</style>
