import Vue from 'vue';
import Vuex from 'vuex';
import filtersChangePlugin from './plugins/filters';
import paginationChangePlugin from './plugins/pagination';
import calendarChangePlugin from './plugins/calendar';
import apiHelper from './helpers/api';
import helpers from '../helpers';
import axios from '../plugins/axios';
import diffService from './services/diff';
import filtersApiService from './api/filters';
import config from '../config';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    payloadModalOpen: false,
    payloadModalContent: '{}',
    diffModalOpen: false,
    diffModalContent: '{}',
    requestLoading: false,
    filters: {},
    applications: [],
    selectedApplication: null,
    entities: [],
    selectedEntity: null,
    actions: [],
    selectedAction: null,
    countryCode: config.getParameter('country'),
    logs: [],
    perPage: 10,
    page: 1,
    warningStatus: false,
    warningText: '',
    numberOfLogs: 0,
    startDate: null,
    endDate: null,
    textSearchType: null,
    textSearchValue: '',
  },
  mutations: {
    changePayloadModalOpenStatus(state, val) {
      state.payloadModalOpen = val;
    },
    changePayloadModalContent(state, val) {
      state.payloadModalContent = val;
    },
    setDiffModalOpenStatus(state, val) {
      state.diffModalOpen = val;
    },
    setDiffPayloadModalContent(state, val) {
      state.diffModalContent = val;
    },
    isLoading(state, value) {
      state.requestLoading = value;
    },
    setLogs(state, val) {
      state.logs = val;
    },
    setFilters(state, val) {
      state.filters = val;
    },
    setApplications(state, val) {
      state.applications = val;
    },
    setActions(state, val) {
      state.actions = val;
    },
    setEntities(state, val) {
      state.entities = val;
    },
    setApplication(state, val) {
      state.selectedApplication = val;
    },
    setEntity(state, val) {
      state.selectedEntity = val;
    },
    setAction(state, val) {
      state.selectedAction = val;
    },
    setPage(state, val) {
      state.page = val;
    },
    setPerPage(state, val) {
      state.perPage = val;
    },
    setWarningStatus(state, val) {
      state.warningStatus = val;
    },
    setWarningText(state, val) {
      state.warningText = val;
    },
    setNumberOfLogs(state, val) {
      state.numberOfLogs = val;
    },
    setCountryCode(state, val) {
      state.countryCode = val;
    },
    setStartDate(state, val) {
      state.startDate = val;
    },
    setEndDate(state, val) {
      state.endDate = val;
    },
    setTextSearchType(state, val) {
      state.textSearchType = val;
    },
    setTextSearchValue(state, val) {
      state.textSearchValue = val;
    },
  },
  getters: {
    getPayloadModalOpenStatus: (state) => state.payloadModalOpen,
    getPayloadModalContent: (state) => state.payloadModalContent,
    getDiffModalStatus: (state) => state.diffModalOpen,
    getDiffModalContent: (state) => state.diffModalContent,
    isRequestLoading: (state) => state.requestLoading,
    getLogs: (state) => state.logs,
    getPage: (state) => state.page,
    getApplications: (state) => state.applications,
    getEntities: (state) => state.entities,
    getActions: (state) => state.actions,
    getCountryCode: (state) => state.countryCode,
    getWarningStatus: (state) => state.warningStatus,
    getWarningText: (state) => state.warningText,
    getNumberOfLogs: (state) => state.numberOfLogs,
    getApplication: (state) => state.selectedApplication,
    getEntity: (state) => state.selectedEntity,
    getAction: (state) => state.selectedAction,
    getStartDate: (state) => state.startDate,
    getEndDate: (state) => state.endDate,
    getTextSearchType: (state) => state.textSearchType,
    getTextSearchValue: (state) => state.textSearchValue,
  },
  plugins: [
    filtersChangePlugin,
    paginationChangePlugin,
    calendarChangePlugin,
  ],
  actions: {
    fetchInitialData(context) {
      return filtersApiService.getApplications(context);
    },
    fetchLogsFromApi(context) {
      if (helpers.isNullOrEmpty(context.rootState.selectedApplication)) {
        context.dispatch('removeApplicationInformation');
        return context.dispatch({
          type: 'openWarningModal',
          payload: 'You need to select an application before searching.',
        });
      }

      context.commit('isLoading', true);
      const state = context.rootState;

      const params = apiHelper.mapFiltersToApiRequest(
        state,
        state.page,
        state.perPage,
        state.countryCode,
      );

      return axios.get('/v2/search', { params })
        .then((response) => {
          const lengthResponse = response.data.results.length;
          const numberOfLogs = state.page * state.perPage + state.page;
          context.commit('setLogs', response.data.results ?? []);
          context.commit('setNumberOfLogs', lengthResponse >= state.perPage ? numberOfLogs : lengthResponse);
        })
        .catch((err) => {
          context.dispatch({
            type: 'openWarningModal',
            payload: `Api request failed with error: ${err.response.data}`,
            code: err.response.status,
          });
        })
        .finally(() => context.commit('isLoading', false));
    },
    getDiff(context, payload) {
      context.commit('isLoading', true);
      const state = context.rootState;
      const params = apiHelper.mapGetDiffRequest(payload, state.countryCode);

      return axios.get('/v2/search/previous', { params })
        .then((response) => {
          const diff = diffService(response.data, payload);
          context.dispatch('openJsonViewerModal', diff);
        })
        .catch((err) => {
          context.dispatch({
            type: 'openWarningModal',
            payload: `Api request failed with error: ${err.toString()}`,
            code: err.response.status,
          });
        })
        .finally(() => context.commit('isLoading', false));
    },
    openWarningModal(context, { payload, code }) {
      if (code === 403) {
        payload = 'You are not authorized to access this application';
      }
      context.commit('setWarningText', payload);
      context.commit('setWarningStatus', true);
    },
    openJsonViewerModal(context, payload) {
      context.commit('setDiffPayloadModalContent', payload);
      context.commit('setDiffModalOpenStatus', true);
    },
    resetAllFilters(context) {
      context.rootState.selectedApplication = null;
      context.rootState.selectedEntity = null;
      context.rootState.selectedAction = null;
      context.rootState.startDate = null;
      context.rootState.endDate = null;
      context.rootState.textSearchType = null;
      context.rootState.textSearchValue = '';
      context.rootState.actions = [];
      context.rootState.entities = [];
      context.rootState.logs = [];
      context.rootState.numberOfLogs = 0;
    },
    removeApplicationInformation(context) {
      context.rootState.selectedApplication = null;
      context.rootState.selectedEntity = null;
      context.rootState.selectedAction = null;
      context.rootState.actions = [];
      context.rootState.entities = [];
      context.rootState.logs = [];
      context.rootState.numberOfLogs = 0;
    },
  },
});

export default store;
