<template>
  <v-container fluid>
    <v-row align="center">
      <v-col
        class="d-flex"
        cols="12"
        sm="3"
      >
        <v-select
          :value="application"
          :items="applications"
          clearable
          label="Select Application"
          @input="setApplication"
          @click.stop="setApplication(null)"
        />
      </v-col>

      <v-col
        class="d-flex"
        cols="12"
        sm="3"
      >
        <v-select
          :value="entity"
          :items="entities"
          clearable
          label="Select Entity"
          @input="setEntity"
          @click.stop="setEntity(null)"
        />
      </v-col>
      <v-col
        class="d-flex"
        cols="12"
        sm="3"
      >
        <v-select
          :value="action"
          :items="actions"
          clearable
          label="Select Action"
          @input="setAction"
          @click.stop="setAction(null)"
        />
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col
        class="d-flex"
        cols="12"
        sm="3"
      >
        <v-menu
          :close-on-content-click="true"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="startDate"
              :disabled="!canSelectDates"
              label="From"
              clearable
              prepend-icon="date_range"
              readonly
              v-on="on"
              @click:clear="resetStartDate"
            />
          </template>
          <v-date-picker
            :value="startDate"
            :max="dateToday"
            @click:date="setStartDate"
          />
        </v-menu>
      </v-col>

      <v-col
        class="d-flex"
        cols="12"
        sm="3"
      >
        <v-menu
          :close-on-content-click="true"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="endDate"
              :disabled="!canSelectDates"
              label="To"
              prepend-icon="date_range"
              readonly
              clearable
              v-on="on"
              @click:clear="resetEndDate"
            />
          </template>
          <v-date-picker
            :value="endDate"
            :min="startDate"
            :max="dateToday"
            @click:date="setEndDate"
          />
        </v-menu>
      </v-col>
      <v-col
        class="d-flex"
        cols="12"
        sm="3"
      >
        <v-col
          class="d-flex no-padding-left"
          sm="12"
          md="6"
        >
          <v-select
            :value="textSearchType"
            :items="searchTypes"
            clearable
            label="Select Action"
            @input="setSearchType"
          />
        </v-col>

        <v-col
          class="d-flex no-padding-right"
          sm="12"
          md="6"
        >
          <v-text-field
            :value="textSearchValue"
            label="Search Text"
            @change="submitTextSearch"
            @keyup.enter.native="triggerSearch"
          />
        </v-col>
      </v-col>
      <v-col
        class="d-flex"
        cols="12"
        sm="3"
      >
        <v-col
          class="d-flex"
          sm="12"
          md="6"
        >
          <v-btn
            class="full-width"
            @click="triggerSearch"
          >
            Search
          </v-btn>
        </v-col>
        <v-col
          class="d-flex"
          sm="12"
          md="6"
        >
          <v-btn
            class="full-width"
            @click="resetFilters"
          >
            Reset Filters
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
    <WarningComponent />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import WarningComponent from './Warning.vue';

export default {
  components: {
    WarningComponent,
  },
  data: () => ({
    searchTypes: [
      { text: 'Entity ID', value: 'entity_id' },
      { text: 'Entity Name', value: 'entity_name' },
      { text: 'User Email', value: 'email_user' },
    ],
    dateToday: moment().format('YYYY-MM-DD'),
    canSelectDates: false,
  }),
  computed: {
    ...mapGetters({
      applications: 'getApplications',
      entities: 'getEntities',
      actions: 'getActions',
      application: 'getApplication',
      entity: 'getEntity',
      action: 'getAction',
      textSearchType: 'getTextSearchType',
      textSearchValue: 'getTextSearchValue',
      startDate: 'getStartDate',
      endDate: 'getEndDate',
    }),
  },
  watch: {
    entity(val) {
      this.canSelectDates = !!val;
    },
  },
  methods: {
    setApplication(val) {
      this.$store.commit('setApplication', val);
    },
    setEntity(val) {
      this.$store.commit('setEntity', val);
    },
    setAction(val) {
      this.$store.commit('setAction', val);
    },
    setStartDate(val) {
      this.$store.commit('setStartDate', val);
    },
    setEndDate(val) {
      this.$store.commit('setEndDate', val);
    },
    triggerSearch() {
      this.$store.dispatch('fetchLogsFromApi');
    },
    resetFilters() {
      this.$store.dispatch('resetAllFilters');
    },
    setSearchType(val) {
      this.$store.commit('setTextSearchType', val);
    },
    submitTextSearch(val) {
      this.$store.commit('setTextSearchValue', val);
    },
    resetStartDate() {
      this.setStartDate(null);
    },
    resetEndDate() {
      this.setEndDate(null);
    },
  },
};
</script>

<style scoped>
    .no-padding-left {
        padding-left: 0 !important;
    }

    .no-padding-right {
        padding-right: 0 !important;
    }

    .full-width {
        width: 100% !important;
    }
</style>
