import Vue from 'vue';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from './plugins/axios';
import 'vuetify/dist/vuetify.min.css';
import './plugins/handlers/error';
import ACLModule from './acl-module';

Vue.prototype.$http = axios;

Vue.config.productionTip = false;

ACLModule.requestUserAccess().catch(() => {
  ACLModule.redirectToSignIn();
});

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
